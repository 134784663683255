import { APOLLO_OPTIONS, Apollo, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InjectionToken, NgModule } from "@angular/core";
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  NextLink,
  Operation,
} from "@apollo/client/core";
import { environment } from "../environments/environment";
import { setContext } from "@apollo/client/link/context";
import { makeStateKey, TransferState } from "@angular/platform-browser";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
const uri = environment.serverUrl;
const APOLLO_CACHE = new InjectionToken<InMemoryCache>("apollo-cache");
const STATE_KEY = makeStateKey<any>("apollo.state");

export function createApollo(
  httpLink: HttpLink,
  cache: InMemoryCache,
  transferState: TransferState
): ApolloClientOptions<any> {
  // To add token in request / we can set up refresh token logic if we want by comparing token
  const isBrowser = transferState.hasKey<any>(STATE_KEY);
  if (isBrowser) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
  } else {
    transferState.onSerialize(STATE_KEY, () => {
      return cache.extract();
    });
    // Reset cache after extraction to avoid sharing between requests
    cache.reset();
  }
  const auth = setContext((_, { headers }) => {
    let token = "";
    if (localStorage) token = localStorage.getItem("token") ?? "";
    if (localStorage) {
      const orgId = localStorage.getItem("orgId") ?? "";
      if (orgId) {
        headers = {
          ...headers,
          orgId,
        };
      }
    }
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const link = auth.concat(createUploadLink({ uri }));

  return {
    link,
    cache,
    ssrMode: typeof window === "undefined",
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache(),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, APOLLO_CACHE, TransferState],
    },
    Apollo,
  ],
})
export class GraphQLModule {}
