<div class="layout-sidebar">
    <div class="sidebar-header">
        <a class="app-logo cursor-pointer" href="/dashboard">
            <!-- Following image will be toggle on collapse and expand -->
            <img src="../../assets/Images/short-logo.png" class="app-logo-single h-2rem"/> 
            <img src="../../assets/Images/long-logo.png" class="app-logo-normal"/> 
        </a>
        <button type="button" class="layout-sidebar-anchor  anchor-button cursor-pointer" (click)="addAnchor()"></button>
    </div>
    <div class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>