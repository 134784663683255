import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  orgId : string | null = null;
  constructor(private router: Router, private utilService : UtilsService) {
    utilService.organizationViewSubscription.subscribe(orgId => this.orgId = orgId);
   }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const loggedInUser = JSON.parse(localStorage.getItem('user') || '{}');
    const orgId = localStorage.getItem('orgId');
    if (!route.data['role'] || (route.data['role'].includes(4) && orgId) || route.data['role'].length === 0 || route.data['role'].includes(loggedInUser.roleId)) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
