<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger transition-duration-300"
            (click)="toggleMenu()"><i class="pi pi-bars"></i></button>
    </div>
    <div class="layout-topbar-menu-section" (mouseenter)=" layoutService.isDesktop() ? toggleMenu() : false"
        (mouseleave)="layoutService.isDesktop() ? toggleMenu() : false">
        <app-sidebar></app-sidebar>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
            <!-- By default loggedInUser.id is set to  -1 -->
            @if(loggedInUser && loggedInUser.id !== -1 && loggedInUser.firstName && loggedInUser.lastName){
                <span>{{loggedInUser.firstName + " " + loggedInUser.lastName}}</span>
            }
            <li class="topbar-item"><a class="p-element p-ripple cursor-pointer">
                    <img src="../../assets/Images/avatar-m-1.jpg"
                        alt="Profile" class="border-round-xl"><span class="p-ink" aria-hidden="true"
                        role="presentation"></span></a>
            </li>
            <li class="topbar-item" (click)="logout()"><a class="p-element p-ripple cursor-pointer">
                    <i class="pi pi-fw pi-sign-out text-xl mr-2"></i>
                </a>
            </li>
        </ul>
    </div>
</div>