import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { LayoutService } from './service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopBarComponent } from './app.topbar.component';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
})
export class AppLayoutComponent implements OnDestroy, OnInit {
  overlayMenuOpenSubscription: Subscription = new Subscription();

  menuOutsideClickListener: any;

  profileMenuOutsideClickListener: any;

  isLandingPage: boolean = true;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

  show = false;

  private subscription: Subscription = new Subscription();

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    public activeRouter: ActivatedRoute,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {
    // this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
    //     if (!this.menuOutsideClickListener) {
    //         this.menuOutsideClickListener = this.renderer.listen('document', 'click', event => {
    //             const isOutsideClicked = !(this.appSidebar.el.nativeElement.isSameNode(event.target) || this.appSidebar.el.nativeElement.contains(event.target)
    //                 || this.appTopbar.menuButton.nativeElement.isSameNode(event.target) || this.appTopbar.menuButton.nativeElement.contains(event.target));

    //             if (isOutsideClicked) {
    //                 this.hideMenu();
    //             }
    //         });
    //     }

    //     if (!this.profileMenuOutsideClickListener) {
    //         this.profileMenuOutsideClickListener = this.renderer.listen('document', 'click', event => {
    //             const isOutsideClicked = !(this.appTopbar.menu.nativeElement.isSameNode(event.target) || this.appTopbar.menu.nativeElement.contains(event.target)
    //                 || this.appTopbar.topbarMenuButton.nativeElement.isSameNode(event.target) || this.appTopbar.topbarMenuButton.nativeElement.contains(event.target));

    //             if (isOutsideClicked) {
    //                 this.hideProfileMenu();
    //             }
    //         });
    //     }

    //     if (this.layoutService.state.staticMenuMobileActive) {
    //         this.blockBodyScroll();
    //     }
    // });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
        this.hideProfileMenu();
      });
  }

  ngOnInit(): void {
    this.isLandingPage = this.router.url === '/';    
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.isLandingPage = event.urlAfterRedirects === '/';
    });
    this.subscription = this.loaderService.loaderState
    .subscribe((state: boolean) => {
      setTimeout(() => {
        this.show = state;
        this.cdr.detectChanges();
      });
    });

  }

  hideMenu() {    
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }
  }

  hideProfileMenu() {
    this.layoutService.state.profileSidebarVisible = false;
    if (this.profileMenuOutsideClickListener) {
      this.profileMenuOutsideClickListener();
      this.profileMenuOutsideClickListener = null;
    }
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  get containerClass() {
    return {
      'layout-theme-light': this.layoutService.config().colorScheme === 'light',
      'layout-theme-dark': this.layoutService.config().colorScheme === 'dark',
      'layout-overlay': this.layoutService.config().menuMode === 'overlay',
      // 'layout-static': this.layoutService.config().menuMode === 'static',
      'layout-sidebar-anchored':
        this.layoutService.state.staticMenuDesktopAnchored,
      'layout-sidebar-active':
        this.layoutService.state.staticMenuDesktopInactive ||
        this.layoutService.state.staticMenuDesktopAnchored,
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config().inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config().ripple,
    };
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }

    this.subscription.unsubscribe();
  }
}
