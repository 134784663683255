import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { User } from '../shared/interfaces/user.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  overlayMenuOpenSubscription: Subscription;

  menuOutsideClickListener: any;

  loggedInUser : User;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  constructor(
    public layoutService: LayoutService,
    public renderer: Renderer2,
    public router: Router,
    private authService: AuthService,
  ) {
    // To handle outside click for the overlay menu in the mobile view
    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appSidebar.el.nativeElement.isSameNode(event.target) ||
                this.appSidebar.el.nativeElement.contains(event.target) ||
                this.menuButton.nativeElement.isSameNode(event.target) ||
                this.menuButton.nativeElement.contains(event.target)
              );

              if (isOutsideClicked) {                
                this.hideMenu();
              }
            }
          );
        }

        if (this.layoutService.state.staticMenuMobileActive) {
          this.blockBodyScroll();
        }
      });

    // To collapse / hide menu when navigation end to the new page
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
      });
  }

  ngOnInit(): void {
    // Will fetch user from the local storage
    this.loggedInUser = this.authService.getLoggedInUser();
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = this.layoutService.isMobile();
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }
  }

  // Block body scroll for mobile in case of expanded menu
  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  // open/expand - close/collapse menu
  toggleMenu() {
    this.layoutService.onMenuToggle();
  }

  // Will logout user and redirected to the landing page
  logout(){
    this.authService.logoutUser();
  }
}
