import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import {
  from as observableFrom,
  Observable,
  switchMap,
  map,
  catchError,
  throwError,
} from "rxjs";
import { AuthService } from "../shared/services/auth.service";
import { LoaderService } from "../shared/services/loader.service";
import { UtilsService } from "../shared/services/utils.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class httpConfigInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private utilsService: UtilsService,
    private authService: AuthService,
    private router: Router
  ) {} // private toast: ToastService,
  requestUrl: string;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestUrl = request.url;    
    return observableFrom(this._getToken()).pipe(
      switchMap(() => {
        if(this.requestUrl.includes('api')){          
          this.loaderService.showLoader();
          
          return next.handle(request).pipe(
            map((response) => {
              this.loaderService.hideLoader();
              return response;
            }),
            catchError((error) => {
              this.loaderService.hideLoader();
              if (error && error.status === 401) {
                this.utilsService.clearLocalStorage();
                this.router.navigate(['/']);
                return throwError(() => error);
              }
              return throwError(() => error);
            })
          ) as any as Observable<HttpEvent<any>>;
        };
        return next.handle(request)
      })
    );
  }

  // If we need to setup refresh token add configuration here and remove it from graphql.module.ts
  async _getToken(): Promise<string> {
    return this.authService.getToken();
  }
}
