import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { LayoutService } from "./service/app.layout.service";
import { User } from "../shared/interfaces/user.model";
import { UtilsService } from "../shared/services/utils.service";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  loggedInUser: User;
  orgId: string | null = null;

  constructor(
    public layoutService: LayoutService,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem("user") || "{}");
    this.orgId = localStorage.getItem("orgId");
    this.utilService.organizationViewSubscription.subscribe((orgId) => {
      this.loggedInUser = JSON.parse(localStorage.getItem("user") || "{}");
      this.orgId = orgId;
    });
    this.model = [
      {
        label: "Home",
        items: [
          {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            routerLink: ["/dashboard"],
          },
        ],
        role: [2, 3],
      },
      {
        label: "Data",
        items: [
          {
            label: "Projects",
            icon: "pi pi-fw pi-id-card",
            routerLink: ["/project"],
          },
          {
            label: "Reconciliation",
            icon: "pi pi-fw pi-check-square",
            routerLink: ["/reconciliation"],
          },
        ],
        role: [2, 3, 4],
      },
      {
        label: "Organization",
        items: [
          {
            label: "Shape",
            icon: "pi pi-fw pi-calculator",
            routerLink: ["/shape"],
          },
        ],
        role: [3, 4],
      },
      {
        label: "User",
        items: [
          {
            label: "Users",
            icon: "pi pi-fw pi-users",
            routerLink: ["/user"],
          },
        ],
        role: [3, 4],
      },
      {
        label: "Admin",
        items: [
          {
            label: "Organization",
            icon: "pi pi-fw pi-users",
            routerLink: ["/organization"],
          },
        ],
        role: [1],
      },
    ];
  }

  roleIncluded(item: any): boolean {
    const { role } = item;
    return (
      (role.includes(4) && this.orgId) ||
      !role ||
      role.length === 0 ||
      role.includes(this.loggedInUser.roleId)
    );
  }
}
