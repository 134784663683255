@if (isLandingPage) {
<router-outlet></router-outlet>
}@else {
<div class="layout-container layout-light layout-light-menu layout-transparent-topbar layout-drawer"
    [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <!-- <div class="layout-sidebar">
            <app-sidebar></app-sidebar>
        </div> -->
    <div class="layout-content-wrapper">
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
    <div class="layout-mask"></div>
    <div *ngIf="show" style="display: block; position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background-color: var(--maskbg);
    display: flex;
    justify-content: center;
    align-items: center;
">
        <img style="border-radius: 10%;" src="../../assets/Images/loading.gif" />
    </div>
</div>
}